import React, { useMemo } from 'react'
import { EMPTY_IMAGE } from 'styleguide/packages/util/index.js'
// import { cloudinaryImage } from 'services/util'

if (typeof window !== 'undefined') {
  const lazySizes = require('lazysizes')
  require('lazysizes/plugins/attrchange/ls.attrchange')
  require('lazysizes/plugins/bgset/ls.bgset')
  require('lazysizes/plugins/parent-fit/ls.parent-fit')

  const config = lazySizes.cfg
  config.loadMode = 1 // load only elements in the viewport
  config.loadHidden = false
  config.preloadAfterLoad = true // load everything else when DOM is loaded
  config.ricTimeout = 500 // request idle callback
  config.throttleDelay = 150
  lazySizes.init()
}

// React.Fragment that does not throw error when props contain anything else than key and children
const NoContainer = ({ children }) => <>{children}</>

const CLOUDINARY_IMAGE_OPTIONS = [
  'format',
  'quality',
  'width',
  'height',
  'gravity',
  'crop',
  'zoom',
  'dpr',
  'hasWatermark',
]

const Img = props => {
  const { src, lazy = true, publicId, container, className = '', ...rest } = props
  const Container = container || NoContainer

  const [url, imgProps] = useMemo(() => {
    const imgProps = { ...rest }
    const cloudinaryImageOptions = {}
    for (const key of CLOUDINARY_IMAGE_OPTIONS) {
      cloudinaryImageOptions[key] = imgProps[key]
      delete imgProps[key]
    }

    // const url = publicId ? cloudinaryImage(publicId, cloudinaryImageOptions) : src
    const url = src

    return [url, imgProps]
  }, [publicId, src, ...Object.values(rest)])

  return (
    <Container className={className}>
      <img
        src={lazy ? EMPTY_IMAGE : url}
        data-src={lazy ? url : null}
        className={`${lazy ? 'lazyload' : ''} ${container ? '' : className}`}
        {...imgProps}
      />
    </Container>
  )
}

export default Img
